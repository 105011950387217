/*jslint browser: true */
/*global Raven */
(function () {
    'use strict';

    var dsn = window.document.body.getAttribute('data-sentry'),
        user_id = window.document.body.getAttribute('data-user-id');

    /* Install the Sentry client if a DSN is sepcified */
    if (dsn) {
        Raven.config(dsn).install();
    }

    /* Flag events as belonging to a specific user if the user is logged in */
    if (dsn && user_id) {
        Raven.setUser({
            id: user_id
        });
    }

}());
